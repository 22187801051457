<template>
  <div class="bottom-bar" v-if="!$auth.loading" @click="$parent.toggleCart()">
    <span class="icon-and-circle-container">
      <i class="fa fa-cart-plus"></i>
      <div v-if="!!carts_by_pk && carts_by_pk.cart_items.length > 0" class="circle-quantity">
        <span class="product-number">{{ carts_by_pk.cart_items.length }}</span>
      </div>
    </span>
    BASKET
  </div>
</template>

<script>
import { GET_CART_ITEMS } from './CartTable.vue';

export default {
  name: 'BottomBar',
  apollo: {
    carts_by_pk: {
      query: GET_CART_ITEMS,
      fetchPolicy: 'cache-first',
      variables() {
        return {
          cartID: this.$root.$data.cartId,
        };
      },
      skip() {
        return !this.$root.$data.cartId;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
/* Bottom Menu: */
//********RESPONSIVE DESIGN********//
@media (min-width: 800px) {
  .bottom-bar {
    display: none;
  }
}

@media (max-width: 800px) {
  .bottom-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: $mg_theme;
    color: $wh;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    z-index: 999;
  }
  .icon-and-circle-container {
    position: relative;
  }
  .fa-cart-plus {
    font-size: 30px;
    margin-right: 15px;
  }
  .circle-quantity {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: inline-block;
    background-color: $cqc;
    position: absolute;
    bottom: 0;
    left: 15px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
    .product-number {
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      min-width: 14px;
      padding: 4px 1px 0 2px;
    }
  }
}
</style>
