import Vue from 'vue';

Vue.mixin({
  methods: {
    findExistingCart() {
      console.log('Searching for cart...');
      // Check in Memory
      if (this.$root.$data.cartId) {
        console.log('Found in memory ...');
        return this.$root.$data.cartId;
      }
      // Check in localStorage
      if (localStorage.cartId) {
        console.log('Found in localStorage ...');
        return localStorage.cartId;
      }
      return null;
    },
  },
});
