<template>
  <div
    class="img-container"
    :style="{
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),' + 'url(' + img + ')',
    }"
  >
    <div class="hero-text">
      <h5 class="upperCase">Unser leckeres Essen zum Bestellen</h5>
    </div>
    <div class="hero-btns" v-if="!$auth.loading">
      <button v-if="!$auth.isAuthenticated" @click="login">Log in</button>
      <button v-if="$auth.isAuthenticated" @click="logout">Log out</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroImage',
  data() {
    return {
      img: 'https://source.unsplash.com/800x400/?food,meals',
    };
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  props: {
    image: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

@media screen and (max-width: 600px) {
  .hero-text {
    display: none;
    text-shadow: 2px 2px 4px $bl;
  }
  .hero-btns {
    margin-top: 25px;
  }
}
.img-container {
  width: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}
.hero-text {
  color: $ws;
  padding-top: 40px;
  text-shadow: 2px 2px 4px $bl;
}
.hero-btns {
  margin-bottom: 15px;
}
.upperCase {
  text-transform: uppercase;
}

//********RESPONSIVE DESIGN********//
@media screen and (max-width: 800px) {
  .user-email {
    display: none;
  }
  .img-container {
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
</style>
