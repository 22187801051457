<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <!-- DELIVERY HERO CONTAINER -->
    <hero-container
      :mainLogoImgSrc="mainLogoImgSrc"
      :title="txt.deliveryHeroTitle"
      :subtitle="txt.deliveryHeroSubtitle"
      :paraHeroContainer="txt.deliveryParagraphHeroContainer"
      :btn="txt.deliveryButton"
    ></hero-container>

    <!-- DELIVERY MAIN SECTIONS WITH ARTICLE -->
    <div class="delivery-main">
      <div class="delivery-main-section">
        <div class="delivery-article-video-wrapper">
          <div class="delivery-article">
            <h3>{{ txt.deliveryTitle }}</h3>
            <p>{{ txt.deliveryDescFirstPara }}</p>
            <p>{{ txt.deliveryDescSecondPara }}</p>
          </div>
          <div class="delivery-video">
            <section>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/c0VlDOHyEvY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </section>
          </div>
        </div>
        <div class="video-btn-parent">
          <button class="video-btn">
            <a :href="txt.deliveryVideoLink">{{ txt.deliveryVideoButton }}</a>
          </button>
        </div>
      </div>
      <!-- DELIVERY ORDERING SECTION COMPONENT -->
      <delivery-ordering-section></delivery-ordering-section>
    </div>
  </div>
</template>

<script>
import HeroContainer from '../components/HeroContainer.vue';
import DeliveryOrderingSection from '../components/DeliveryOrderingSection.vue';
import Spinner from '../components/Spinner.vue';
export default {
  components: { HeroContainer, DeliveryOrderingSection, Spinner },
  name: 'Lieferung',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-delivery/delivery-texts.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.mainLogoImgSrc = `${cdnUrl}/${data.mainLogoImgPath}`;
        this.showSpinner = false;

      });
  },
  data() {
    return {
      txt: {},
      mainLogoImgSrc: '',
      showSpinner: true,
    };
  },
};
</script>

<style lang="scss">
@import '../scss/_delivery-page.scss';
@import '../scss/_about-page.scss';
</style>
