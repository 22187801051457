<template>
  <div id="app">
    <loading v-if="$auth.loading" />
    <div v-if="!$auth.loading" class="header">
      <div id="main-nav" class="topnav" :class="{ responsive: resNav }">
        <div class="topnav-child">
          <a v-bind:key="route.path" v-for="route in routes">
            <router-link :to="{ path: route.path }" aria-current="page">{{
              route.name
            }}</router-link>
          </a>
        </div>
        <div class="auth-group" v-if="$auth.isAuthenticated">
          <img class="user-img" @click="$router.push('/myprofile')" :src="$auth.user.picture" />
          <span class="user-email">{{ $auth.user.email }}</span>
        </div>
        <div class="icon-group">
          <a @click="toggleCart()">
            <i class="fa fa-shopping-cart fa-2x"></i>
          </a>
          <a class="hide" @click="toggleNav()">
            <i class="fa fa-bars fa-2x"></i>
          </a>
        </div>
      </div>
    </div>
    <cart-popdown v-if="showCart" />
    <router-view v-if="showRv && !$auth.loading" />
    <bottom-bar v-if="showBottomBar" />
    <footer v-if="!$auth.loading">
      <!-- flex flex-center  -->
      <div class="footer flex">
        <div class="footer-text">
          <p @click="$router.push('/kantine')">{{ txt.startseite }}</p>
          <p @click="$router.push('/kontakt')">{{ txt.kontakt }}</p>
          <p @click="$router.push('/imprint')">{{ txt.impressum }}</p>
          <p @click="$router.push('/privacypolicy')">
            {{ txt.datenschutzerklärung }}
          </p>
        </div>

        <div class="footer-logos">
          <div class="insta-img" v-if="txt.instagramIconPath">
            <img :src="instagramIconSrc" alt="instagram-logo" />
          </div>
          <div class="fb-img" v-if="txt.facebookIconPath">
            <a :href="txt.facebookLink">
              <img :src="facebookIconSrc" alt="facebook-logo" />
            </a>
          </div>
          <div class="logo-img" v-if="txt.instagramIconPath">
            <img :src="imgLogoIconSrc" alt="picture-of-a-logo" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import CartPopdown from './components/CartPopdown.vue';
import Loading from './components/Loading.vue';
import routes from './routes';
import BottomBar from './components/BottomBar.vue';

export default {
  name: 'app',
  components: {
    CartPopdown,
    Loading,
    BottomBar,
  },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-footer/footer-texts.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.instagramIconSrc = `${cdnUrl}/${data.instagramIconPath}`;
        this.facebookIconSrc = `${cdnUrl}/${data.facebookIconPath}`;
        this.imgLogoIconSrc = `${cdnUrl}/${data.imgLogoIconPath}`;
      });
  },
  methods: {
    toggleNav: function() {
      this.resNav = !this.resNav;
    },
    toggleCart: function() {
      this.showCart = !this.showCart;
    },
  },
  watch: {
    $route(to, from) {
      this.resNav = false;
      if (from.path !== to.path) {
        to.path === '/' ? (this.showBottomBar = true) : (this.showBottomBar = false);
      }
    },
  },
  data() {
    return {
      txt: {},
      routes: routes.pairs,
      showBottomBar: false,
      resNav: false,
      showCart: false,
      showRv: true,
      instagramIconSrc: '',
      facebookIconSrc: '',
      imgLogoIconSrc: '',
    };
  },
  mounted() {
    this.$root.$data.cartId = this.findExistingCart();
  },
  updated() {
    const items = document.getElementsByClassName('ccm-settings-summoner');
    if (this.$router.currentRoute.path !== '/privacypolicy') {
      items[0] && items[0].classList.add('ccm-hide');
      items[0] && items[0].classList.remove('ccm-show');
    } else {
      items[0] && items[0].classList.add('ccm-show');
      items[0] && items[0].classList.remove('ccm-hide');
    }
  },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';
@import '@/scss/main.scss';

.user-img {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.user-email {
  float: left;
  display: block;
  color: $ca;
  text-align: center;
  padding: 9px 5px;
  text-decoration: none;
  font-size: 13px;
}
</style>
