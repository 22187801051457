<template>
  <div class="parent">
    <hero-image />
    <category-css-menu />
    <div class="home-main" v-if="!$parent.showCart">
      <shop-categories />
      <cart-side />
    </div>
  </div>
</template>

<script>
import HeroImage from '../components/HeroImage.vue';
import ShopCategories from '../components/ShopCategories.vue';
import CartSide from '../components/CartSide.vue';
import CategoryCssMenu from '../components/CategoryCssMenu.vue';
export default {
  name: 'Shop',
  components: {
    HeroImage,
    ShopCategories,
    CartSide,
    CategoryCssMenu,
  },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-home/images.json`)
      .then((response) => response.json())
      .then((data) => {
        this.cat_img = data.cat_img;
        this.$parent.showBottomBar = true;
      });
  },
  data() {
    return {
      msg: `Develop <strong class="highlight">smart</strong> applications in the <strong class="highlight">cloud</strong>`,
      cat_img: {},
    };
  },
};
</script>
