<template>
  <div>
    <!-- FIRST ARTICLE - ABOUT US SECTION -->
    <div class="about-us-main-section">
      <div class="about-us-articles left-article">
        <h5>{{ txt.firstArticleTitle }}</h5>
        <p>{{ txt.firstArticleDesc }}</p>
      </div>
      <div class="img-people-in-office">
        <img :src="businessPeopleImgSrc" alt="business-people-on-a-break" />
      </div>
    </div>
    <!-- SECOND ARTICLE - ABOUT US SECTION -->
    <div class="about-us-main-section food-and-action-days">
      <div class="img-food-on-the-table">
        <img :src="foodOnTheTableImgSrc" alt="food-on-the-table" />
      </div>
      <div class="about-us-articles right-article">
        <h5 class="action-days-section">
          {{ txt.secondArticleTitle }}
        </h5>
        <p class="action-days-section">
          {{ txt.secondArticleDesc }}
        </p>
      </div>
    </div>
    <!-- THIRD ARTICLE - ABOUT US SECTION -->
    <div class="about-us-main-section">
      <div class="about-us-articles left-article">
        <h5>{{ txt.thirdArticleTitle }}</h5>
        <p>{{ txt.thirdArticleDesc }}</p>
      </div>
      <div class="img-small-meeting-group">
        <img :src="smallMeetingGroupImgSrc" alt="small-meeting-group" />
      </div>
    </div>
    <div v-if="txt.fourthArticleTitle">
      <!-- FOURTH ARTICLE - ABOUT US SECTION -->
      <div class="about-us-main-section food-and-action-days">
        <div class="img-food-on-the-table">
          <img :src="tastyFoodImgSrc" alt="food-on-the-table" />
        </div>
        <div class="about-us-articles right-article">
          <h5 class="action-days-section">
            {{ txt.fourthArticleTitle }}
          </h5>
          <p class="action-days-section">
            {{ txt.fourthArticleDesc }}
          </p>
        </div>
      </div>
      <!-- FIFTH ARTICLE - ABOUT US SECTION -->
      <div class="about-us-main-section">
        <div class="about-us-articles left-article">
          <h5>{{ txt.fifthArticleTitle }}</h5>
          <p>{{ txt.fifthArticleDesc }}</p>
        </div>
        <div class="img-small-meeting-group">
          <img :src="openHoursImgSrc" alt="open-hours" />
        </div>
      </div>
      <!-- SIXTH ARTICLE - ABOUT US SECTION -->
      <div class="about-us-main-section food-and-action-days">
        <div class="img-food-on-the-table">
          <img :src="cateringImgSrc" alt="catering-food-on-the-table" />
        </div>
        <div class="about-us-articles right-article">
          <h5 class="action-days-section">
            {{ txt.sixthArticleTitle }}
          </h5>
          <p class="action-days-section">
            {{ txt.sixthArticleDesc }}
          </p>
        </div>
      </div>
      <!-- SEVENTH ARTICLE - ABOUT US SECTION -->
      <div class="about-us-main-section">
        <div class="about-us-articles left-article">
          <h5>{{ txt.seventhArticleTitle }}</h5>
          <p>{{ txt.seventhArticleDesc }}</p>
        </div>
        <div class="img-small-meeting-group">
          <img :src="roomImgSrc" alt="room-img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUsArticles',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-about/about-texts.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.businessPeopleImgSrc = `${cdnUrl}/${data.businessPeopleImgPath}`;
        this.foodOnTheTableImgSrc = `${cdnUrl}/${data.foodOnTheTableImgPath}`;
        this.tastyFoodImgSrc = `${cdnUrl}/${data.tastyFoodImgPath}`;
        this.smallMeetingGroupImgSrc = `${cdnUrl}/${data.smallMeetingGroupImgPath}`;
        this.openHoursImgSrc = `${cdnUrl}/${data.openHoursImgPath}`;
        this.roomImgSrc = `${cdnUrl}/${data.roomImgPath}`;
        this.cateringImgSrc = `${cdnUrl}/${data.cateringImgPath}`;
      });
  },
  data() {
    return {
      txt: {},
      businessPeopleImgSrc: '',
      foodOnTheTableImgSrc: '',
      tastyFoodImgSrc: '',
      smallMeetingGroupImgSrc: '',
      openHoursImgSrc: '',
      roomImgSrc: '',
      cateringImgSrc: '',
    };
  },
};
</script>

<style lang="scss">
@import '../scss/_about-page.scss';
</style>
