<template>
  <div
    class="hero-container"
    :style="{
      background: createBackgroundCss,
    }"
  >
    <div class="text-container">
      <img class="logo-icon" :src="mainLogoImgSrc" alt="shop-logo" />
      <h1 class="hero-title">{{ title }}</h1>
      <h2 class="hero-title">{{ subtitle }}</h2>
      <div class="para-hero-container">
        <p>{{ paraHeroContainer }}</p>
      <a href="#description"><button class="learn-more-btn">
          {{ btn }}</button></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroContainer',
  props: ['mainLogoImgSrc', 'title', 'subtitle', 'paraHeroContainer', 'btn'],
  created() {
    fetch(`${process.env.MTK_CDN}/shop-delivery/delivery-texts.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.heroImgSrc = `${cdnUrl}/${data.heroImgPath}`;
      });
  },
  data() {
    return {
      txt: {},
      heroImgSrc: '',
    };
  },
  computed: {
    createBackgroundCss() {
      return `linear-gradient(118.58deg, #ffffff 34.83%, rgba(255, 255, 255, 0) 61.36%),
    linear-gradient(90.56deg, #ffffff 36.34%, rgba(255, 255, 255, 0) 80.86%),
    url(\'${this.heroImgSrc}\')`;
    },
  },
};
</script>

<style lang="scss">
@import '../scss/_about-page.scss';
@import '@/scss/_variables.scss';

.hero-container {
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .text-container {
    padding-left: 140px;
    text-align: left;
    font-style: normal;
    font-weight: 400;

    .hero-title {
      color: $db;
      font-size: 72px;
      line-height: 92.02px;
      font-family: Sanchez;
      margin-bottom: 1px;
    }

    .logo-icon {
      width: 143px;
      margin-top: 40px;
    }

    .para-hero-container {
      color: $bg;

      p {
        width: 350px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 28px;
      }
    }

    .learn-more-btn {
      background-color: $yb;
      border-radius: 15px;
      color: $bg;
      border: none;
    }
  }
}

//********RESPONSIVE DESIGN********//
@media only screen and (max-width: 1124px) {
  .hero-container {
    .text-container {
      padding-left: 50px;
      padding-bottom: 30px;

      .hero-title {
        font-size: 60px !important;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .hero-container {
    .text-container {
      padding-left: 20px;

      .hero-title {
        font-size: 31px !important;
        line-height: 50px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .hero-container .text-container .para-hero-container p {
    width: 100%;
    padding-right: 30px;
  }
}
</style>
