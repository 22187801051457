import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { from } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo, { ApolloProvider } from 'vue-apollo';
import { setContext } from 'apollo-link-context';
import { getInstance } from './auth';
import { onError } from 'apollo-link-error';

// Install the vue-apollo plugin
Vue.use(VueApollo);

const errCSS =
  'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;';

const getToken = async () => {
  const token = await getInstance().getTokenSilently();
  return token ? `Bearer ${token}` : '';
};

const authLink = setContext(async () => ({
  headers: {
    'X-Hasura-Role': 'user',
    Authorization: await getToken(),
  },
}));

const httpLink = new HttpLink({
  uri: process.env.GQL_ENDPOINT,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const defaultOptions = {
  cache: new InMemoryCache(),
  connectToDevTools: true,
};

function createProviderFromClient(apolloClient) {
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      console.log('%cError', errCSS, error.message);
    },
  });
  return apolloProvider;
}

export function createAnonProvider(options = {}) {
  // Create apollo client
  const apolloClient = new ApolloClient({
    ...{
      link: from([errorLink, httpLink]),
    },
    ...defaultOptions,
    ...options,
  });
  return createProviderFromClient(apolloClient);
}

// Call in App.vue
export function createProvider(options = {}) {
  // Create apollo client
  const apolloClient = new ApolloClient({
    ...{
      link: from([authLink, errorLink, httpLink]),
    },
    ...defaultOptions,
    ...options,
  });
  return createProviderFromClient(apolloClient);
}
