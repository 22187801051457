<template>
  <div class="cantine-container">
    <div class="select-time-wrapper">
      <div class="select-time-container">
        <label for="selectTime">{{ txt.selectTime }}</label>
        <select class="choose-time" name="choose-time" v-model="order_fields.hour">
          <option v-for="hour in txt.hourOptions" :key="hour" :value="hour">{{ hour }}</option>
        </select>
      </div>
    </div>
    <h3>{{ $parent.sale_date | deLongDate }}, {{ order_fields.hour }}</h3>
    <div v-for="cat in categories" :key="cat.id">
      <div class="cantine-category">
        <div :id="cat.name"></div>
        <h3 class="cantine-category-title">{{ cat.name }}</h3>
        <hr class="horizontal-category-line" />
      </div>
      <div class="cantine-categories-container">
        <cantine-product
          v-for="product in cat.products"
          :key="product.id"
          :product="product"
          :hour="order_fields.hour"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import CantineProduct from './CantineProduct.vue';

const CREATE_ORDER = gql`
  mutation createOrder($order: CreateOrderInsertInput! = {}, $cart_id: uuid!) {
    createOrder(order: $order, cart_id: $cart_id) {
      id
      user_id
      order_items
      message
      payment_url
    }
  }
`;
const GET_ALL = gql`
  query getCantineDaySales($sale_date: date!) {
    categories(where: { shop_type_id: { _eq: "2" } }) {
      id
      name
      slug
      products(where: { on_sale_from: { _lte: $sale_date }, on_sale_to: { _gte: $sale_date } }) {
        id
        name
        body
        alergens
        img_url
        price
        on_sale_from
        on_sale_to
      }
    }
  }
`;

export default {
  name: 'CantineCategories',
  components: { CantineProduct },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-checkout/checkout.json`)
      .then((response) => response.json())
      .then((data) => {
        this.txt = data;
        this.order_fields = { ...data.order_fields, ...this.order_main_fields };
        this.showSpinner = false;
      });
  },
  apollo: {
    categories: {
      query: GET_ALL,
      variables() {
        return {
          sale_date: this.$parent.sale_date,
        };
      },
    },
  },
  data() {
    return {
      txt: {
        order_fields: {},
      },
      order_fields: {
        address_city: '',
        address_country: '',
        address_line_1: '',
        address_postal_code: '',
        address_state: '',
        user_comment: '',
      },
      categories: [],
      order_main_fields: {
        hour: '13:00Uhr',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_cantine-categories';
@import '@/scss/_variables.scss';
</style>
