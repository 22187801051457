<template>
  <div class="categories">
    <div class="categories-category shadow" v-for="cat in categories" :key="cat.id">
      <div class="categories-category-hero">
        <div :id="cat.name"></div>
        <picture>
          <source :srcset="getImg(cat, 'lg')" media="(min-width: 600px)" />
          <img
            class="categories-category-hero-img"
            :src="getImg(cat, 'sm')"
            :alt="cat.name + '-image'"
          />
        </picture>
        <h2 class="categories-category-hero-txt">{{ cat.name }}</h2>
      </div>
      <shop-product v-for="product in cat.products" :key="product.id" :product="product" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import ShopProduct from './ShopProduct.vue';

const GET_ALL = gql`
  {
    categories(where: { shop_type_id: { _eq: "1" } }) {
      id
      name
      slug
      products {
        id
        name
        body
        alergens
        price
      }
    }
  }
`;

export default {
  name: 'ShopCategories',
  components: { ShopProduct },
  apollo: {
    categories: {
      query: GET_ALL,
    },
  },
  methods: {
    getImg: function(c, s) {
      let img;
      if (this.$parent.cat_img.hasOwnProperty(c.slug)) {
        img = `${process.env.MTK_CDN}/shop-home/${c.slug}/${
          this.$parent.cat_img[c.slug][s][
            Math.floor(Math.random() * this.$parent.cat_img[c.slug][s].length)
          ]
        }`;
      } else {
        img = `https://source.unsplash.com/200x100/?food,${c.name}`;
      }
      return img;
    },
  },
  data() {
    return {
      categories: [],
    };
  },
};
</script>
