<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div class="parent">
      <hero-image />
    </div>

    <div class="checkout-container">
      <div class="checkout-cart-table">
        <cart-table />
      </div>
      <div class="checkout-form">
        <form @submit.prevent="orderClick">
          <div class="typing-demo">
            <h4>{{ txt.orderDetailsTitle }}</h4>
          </div>
          <div v-if="showDeliveryMethodDropDwon" class="deliveryMethod">
            <label for="deliveryMethod">{{ txt.deliveryMethod }}</label>
            <select
              id="deliveryMethod"
              name="deliveryMethod"
              v-model="order_fields.type"
              @change="pickByMyself()"
            >
              <option value="for-delivery">{{ txt.deliveryMethodforDelivery }}</option>
              <option value="pick-by-myself">{{ txt.deliveryMethodByMyself }}</option>
            </select>
          </div>
          <div v-if="showDeliveryInfo">
            <div class="deliveryInfoTitle">
              <label for="deliveryInfoTitle">{{ txt.deliveryInfoTitle }}</label>
            </div>
            <div class="form-control">
              <label for="city">{{ txt.city }}</label>
              <input id="city" type="text" v-model="order_fields.address_city" required />
            </div>
            <div class="form-control">
              <label for="country">{{ txt.country }}</label>
              <input id="country" type="text" v-model="order_fields.address_country" required />
            </div>
            <div class="form-control">
              <label for="address_line_1">{{ txt.address }}</label>
              <input
                id="address_line_1"
                type="text"
                v-model="order_fields.address_line_1"
                required
              />
            </div>
            <div class="form-control">
              <label for="postal_code">{{ txt.plz }}</label>
              <input type="text" v-model="order_fields.address_postal_code" required />
            </div>

            <div class="form-control">
              <label for="state">{{ txt.state }}</label>
              <input id="state" type="text" v-model="order_fields.address_state" required />
            </div>
          </div>
          <div class="form-control">
            <label for="user_description">{{ txt.nachricht }}</label>
            <textarea
              id="user_comment"
              name="user_comment"
              type="text"
              v-model="order_fields.user_comment"
              required
            >
            </textarea>
          </div>
          <div class="error-message" v-if="error" @click="error = false">
            <i class="fa fa-exclamation-circle"></i>{{ txt.errorMessage }}
          </div>
          <div class="input-btn-container">
            <button class="input-btn">Jetzt bestellen</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import CartTable from '../components/CartTable.vue';
import HeroImage from '../components/HeroImage.vue';
import Spinner from '../components/Spinner.vue';

const CREATE_ORDER = gql`
  mutation createOrder($order: CreateOrderInsertInput! = {}, $cart_id: uuid!) {
    createOrder(order: $order, cart_id: $cart_id) {
      id
      user_id
      order_items
      message
      payment_url
    }
  }
`;

export default {
  name: 'Checkout',
  components: {
    HeroImage,
    CartTable,
    Spinner,
  },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-checkout/checkout.json`)
      .then((response) => response.json())
      .then((data) => {
        this.txt = data;
        this.order_fields = { ...data.order_fields, ...this.order_main_fields };
        this.showSpinner = false;
        const d = new Date();
        const tmrw = dayjs(d.setDate(d.getDate() + 1)).format('YYYY-MM-DD');
        this.order_fields.deliver_at =
          this.$route.query.deliver_at === undefined ? tmrw : this.$route.query.deliver_at;
      });
  },
  methods: {
    orderClick() {
      this.order_fields.deliver_at = `${
        this.order_fields.deliver_at
      }T${this.order_fields.hour.slice(0, -3)}+02:00`;
      this.createOrder()
        .then((res) => {
          console.log('Order created: ', res.data.createOrder.message);
          this.$root.$data.cartId = null;
          localStorage.removeItem('cartId');
          location.replace(res.data.createOrder.payment_url);
        })
        .catch((error) => {
          console.error(error);
          this.error = true;
        });
    },
    createOrder() {
      return this.$apollo.mutate({
        mutation: CREATE_ORDER,
        variables: {
          order: this.order_fields,
          cart_id: this.$root.$data.cartId,
        },
      });
    },
    pickByMyself() {
      this.showDeliveryInfo = !this.showDeliveryInfo;
    },
  },
  data() {
    return {
      txt: {
        order_fields: {},
      },
      error: false,
      // datePickerReadOnly: !FF_DELIVERY,
      showDeliveryInfo: FF_DELIVERY,
      showDeliveryMethodDropDwon: FF_DELIVERY,
      order_fields: {
        address_city: '',
        address_country: '',
        address_line_1: '',
        address_postal_code: '',
        address_state: '',
        user_comment: '',
      },
      order_main_fields: {
        hour: '13:00Uhr',
        type: FF_DELIVERY ? 'for-delivery' : 'pick-by-myself',
      },
      showSpinner: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_contact-page.scss';
@import '../scss/_checkout-page.scss';
@import '../scss/_variables.scss';
</style>
