// Module routes is single place to register route names
// with their paths. Paths should match the lowercase name of a
// component filename from 'pages/' folder starting with '/'.
// Exported data is used from webpack to generate production
// prerendered files and in App.vue to generate menu links.

const pairs = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Shop',
    path: '/shop',
  },
  {
    name: 'Kantine',
    path: '/kantine',
  },
  {
    name: 'Über uns',
    path: '/uberuns',
  },
  {
    name: 'Lieferung',
    path: '/lieferung',
  },
  {
    name: 'Kontakt',
    path: '/kontakt',
  },
  {
    name: 'Checkout',
    path: '/checkout',
  },
];

const disabledRoutes = typeof FF_DISABLED_ROUTES === 'undefined' ? [] : FF_DISABLED_ROUTES;

module.exports.pairs = pairs.filter((n) => !disabledRoutes.includes(n.path));
module.exports.names = Array.from(pairs, (x) => x.name);
module.exports.paths = Array.from(pairs, (x) => x.path);
