<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div class="parent">
      <img :src="heroImgSrc" alt="Lunch on a table" />
      <div>
        <div class="orderConfirmed">
          <h3>{{ txt.orderConfirmed }}</h3>
        </div>
        <div class="orderConfirmed desc">
          <h6>{{ txt.orderConfirmedDesc }}</h6>
        </div>
        <div>
          <img :src="iconImgSrc" alt="happy-icon-confirmation" />
        </div>
        <div class="orderConfirmed ">
          <!-- <h4>{{ txt.yourOrder }}</h4> -->
        </div>
      </div>
    </div>

    <!-- MAIN CONTAINER -->
    <div class="image-and-table-main-container">
      <!-- IMG CONTAINER -->
      <div class="image-container">
        <div class="lunch-img">
          <img :src="lunchImgSrc" alt="table-lunch-image" />
        </div>
      </div>
      <order-progress />
      <!-- TABLE CONTAINER -->
      <current-orders-table />
    </div>

    <div class="btn-container">
      <button type="button" @click="$router.push('/')">{{ txt.backToHomeBtn }}</button>
      <button type="button" @click="$router.push('/myorders')">{{ txt.ordersBtn }}</button>
      <!-- <button type="button" @click="$router.push('/myorders?order=' + $route.query.order)">
        {{ txt.ordersBtn }}
      </button> -->
    </div>
  </div>
</template>

<script>
import OrderProgress from '../components/OrderProgress.vue';
import CurrentOrdersTable from '../components/CurrentOrdersTable.vue';
import Spinner from '../components/Spinner.vue';
import gql from 'graphql-tag';

const GET_ORDER = gql`
  query myOrder($id: uuid!) {
    orders(where: { id: { _eq: $id } }) {
      confirmed
      product_names
      base_price
      vat
      status
      type
      total_price
      deliver_at
      order_items {
        price
        quantity
        collect_at
        product {
          name
        }
      }
    }
  }
`;

export default {
  components: { CurrentOrdersTable, OrderProgress, Spinner },
  name: 'OrderSuccess',

  created() {
    fetch(`${process.env.MTK_CDN}/shop-order-success/order-success.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.heroImgSrc = `${cdnUrl}/${data.heroLunchImgPath}`;
        this.iconImgSrc = `${cdnUrl}/${data.happyIconImgPath}`;
        this.lunchImgSrc = `${cdnUrl}/${data.lunchImgPath}`;
        this.showSpinner = false;
      });
  },
  apollo: {
    orders: {
      query: GET_ORDER,
      variables() {
        return {
          id: this.$route.query.order,
        };
      },
      pollInterval() {
        return this.pollInterval;
      },
    },
  },
  data() {
    return {
      pollInterval: 120000,
      orders: ['hardcoded'],
      txt: {},
      heroImgSrc: '',
      iconImgSrc: '',
      lunchImgSrc: '',
      showSpinner: true,
    };
  },
  watch: {
    orders(newOrders, oldOrders) {
      console.log(
        'Order status changed from ' + oldOrders[0].status + ' to ' + newOrders[0].status
      );
      if (newOrders[0].status === 'done') {
        this.pollInterval = 600000;
        this.$apollo.queries.orders.stopPolling();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
@import '@/scss/_order-success.scss';
</style>
