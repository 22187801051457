<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div class="imprint-container">
      <!-- TMG -->
      <h2>{{ txt.imprintTitle }}</h2>
      <h3>{{ txt.tmgTitle }}</h3>
      <div class="imprint-main-info">
        <p>{{ txt.tmgDescName }}</p>
        <p>{{ txt.tmgDescCeo }}</p>
        <p>{{ txt.tmgDescStreet }}</p>
        <p>{{ txt.tmgDescCity }}</p>
        <p>{{ txt.tmgDescContact }}</p>
        <p>{{ txt.tmgContact }}</p>
        <p>{{ txt.tmgContactTel }}</p>
        <p>{{ txt.tmgContactMob }}</p>
        <p>{{ txt.tmgContactMail }}</p>
      </div>
      <p>{{ txt.tmgContactLast }}</p>

      <!-- COPYRIGHT SECTION -->
      <h3>
        {{ txt.copyrightTitle }}
      </h3>
      <p>
        <strong>
          {{ txt.copyrightDescFirst }}
        </strong>
      </p>
      <p>
        {{ txt.copyrightDescSecond }}
      </p>

      <!-- AGB SECTION -->
      <h3>
        {{ txt.agbTitle }}
      </h3>
      <h4>{{ txt.agbDesc1 }}</h4>
      <ul>
        <li>{{ txt.agbDesc2 }}</li>
        <li>{{ txt.agbDesc3 }}</li>
        <h4>{{ txt.agbDesc4 }}</h4>
        <li>{{ txt.agbDesc5 }}</li>
        <h4>{{ txt.agbDesc6 }}</h4>
        <li>{{ txt.agbDesc7 }}</li>
        <li>{{ txt.agbDesc8 }}</li>
        <li>{{ txt.agbDesc9 }}</li>
        <li>{{ txt.agbDesc10 }}</li>
        <li>{{ txt.agbDesc11 }}</li>
        <li>{{ txt.agbDesc12 }}</li>
        <li>{{ txt.agbDesc13 }}</li>
        <li>{{ txt.agbDesc14 }}</li>
      </ul>
      <h4>{{ txt.agbDesc15 }}</h4>
      <ul>
        <li>{{ txt.agbDesc16 }}</li>
        <li>{{ txt.agbDesc17 }}</li>
        <li>{{ txt.agbDesc18 }}</li>
      </ul>
      <h4>{{ txt.agbDesc19 }}</h4>
      <p>
        {{ txt.agbDesc20 }} <a :href="txt.agbDesc21">{{ txt.agbDesc21 }} </a>
        {{ txt.agbDesc22 }}
      </p>

      <h4>{{ txt.agbDesc23 }}</h4>
      <p>{{ txt.agbDesc24 }}</p>
      <h4>{{ txt.agbDesc25 }}</h4>
      <p>{{ txt.agbDesc26 }}</p>
      <h4>{{ txt.agbDesc27 }}</h4>
      <p>{{ txt.agbDesc28 }}</p>
      <h4>{{ txt.agbDesc29 }}</h4>
      <p>{{ txt.agbDesc30 }}</p>
      <p>{{ txt.agbDesc31 }}</p>
      <p>{{ txt.agbDesc32 }}</p>
      <p>{{ txt.agbDesc33 }}</p>
      <p>{{ txt.agbDesc34 }}</p>
      <p>{{ txt.agbDesc35 }}</p>
      <p>{{ txt.agbDesc36 }}</p>
      <p>{{ txt.agbDesc37 }}</p>
      <p>{{ txt.agbDesc38 }}</p>
      <h4>{{ txt.agbDesc39 }}</h4>
      <p>{{ txt.agbDesc40 }}</p>
      <div class="agb-info">
        <p>{{ txt.agbDesc41 }}</p>
        <p>{{ txt.agbDesc42 }}</p>
        <p>{{ txt.agbDesc43 }}</p>
        <p>{{ txt.agbDesc44 }}</p>
        <p>{{ txt.agbDesc45 }}</p>
      </div>
      <p>{{ txt.agbDesc46 }}</p>
      <p>{{ txt.agbDesc47 }}</p>
      <p>{{ txt.agbDesc48 }}</p>
      <p>{{ txt.agbDesc49 }}</p>
      <p>{{ txt.agbDesc50 }}</p>
      <p>{{ txt.agbDesc51 }}</p>
      <p>{{ txt.agbDesc52 }}</p>
      <p>{{ txt.agbDesc53 }}</p>
      <p>{{ txt.agbDesc54 }}</p>
      <p>
        {{ txt.agbDesc55 }} <a :href="txt.agbDesc56">{{ txt.agbDesc56 }} </a>
      </p>
    </div>
  </div>
</template>

<script>
import Spinner from '../components/Spinner.vue';

export default {
  components: { Spinner },
  name: 'Imprint',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-imprint/imprint.json`)
      .then((response) => response.json())
      .then((data) => {
        this.txt = data;
        this.showSpinner = false;
      });
  },
  data() {
    return {
      txt: {},
      showSpinner: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
@import '@/scss/_imprint.scss';
</style>
