<template>
  <div class="menu-container">
    <div class="menu-modules">
      <div
        class="module shadow"
        v-for="(d, i) in days"
        :key="i"
        :class="{ active: isActive === d }"
        @click="changeSaleDate(d)"
      >
        <a :href="'#' + d">
          {{ d | deDate }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: 'CantineStickySlideMenu',
  components: {},
  computed: {
    days() {
      let days_arr = [];
      for (var i = 0; i < 15; i++) {
        var the_day = new Date().setDate(this.today.getDate() + i);
        if (dayjs(the_day).day() > 0 && dayjs(the_day).day() < 6) days_arr.push(the_day);
      }
      //Remove todays day from list so user can not buy for today from today
      var today_in_unix = new Date().setDate(this.today.getDate());
      if (today_in_unix == days_arr[0]) days_arr.shift();
      return days_arr;
    },
  },
  methods: {
    changeSaleDate(d) {
      this.$parent.sale_date = dayjs(d).format('YYYY-MM-DD');
      // console.log(this.$parent.sale_date);
      this.isActive = d;
    },
  },
  created() {
    this.isActive = this.days[0];
  },
  data() {
    return {
      today: new Date(),
      isActive: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
.active {
  background-color: $cssma;

  a {
    color: $cssmat;
  }
}
</style>
