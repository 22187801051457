<template>
  <div class="mpbox">
    <div class="mpbtn minus" @click="mpplus()">
      &#x25B2;
    </div>
    <input :value="qty" type="number" id="quantity" name="quantity" min="1" max="25" disabled />
    <div class="mpbtn plus" @click="mpminus()">
      &#x25BC;
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      max: 50,
    };
  },
  props: {
    qty: {
      type: Number,
    },
  },
  methods: {
    mpminus: function() {
      if (this.qty > 1) {
        this.$emit('update:qty', this.qty - 1);
      } else {
        this.$emit('delete', 0);
      }
    },
    mpplus: function() {
      if (this.max === undefined || this.qty < this.max) {
        this.$emit('update:qty', this.qty + 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.mpbox {
  text-align: center;
  display: inline-block;
  user-select: none;
}
#quantity {
  width: 40px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  border: none;
  margin: 0;
}
.mpbox .mpbtn {
  cursor: pointer;
  height: 22px;
}

.mpbox .plus {
  line-height: 2.3rem;
  font-size: 13px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: $mi;
}

.mpbox .minus {
  line-height: 2.2rem;
  font-size: 14px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: $pl;
}
.td-center {
  text-align: unset;
}
//********RESPONSIVE DESIGN********//
@media (max-width: 800px) {
  .td-center {
    text-align: left;
  }
}
</style>
