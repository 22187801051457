<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <!-- ABOUT HERO CONTAINER -->
    <hero-container
      :mainLogoImgSrc="mainLogoImgSrc"
      :title="txt.aboutHeroTitle"
      :subtitle="txt.aboutHeroSubtitle"
      :paraHeroContainer="txt.paragraphHeroContainer"
      :btn="txt.aboutUsButton"
    ></hero-container>

    <!-- ABOUT US MAIN SECTIONS WITH ARTICLES -->
    <div class="about-us-main">
      <div class="about-us-main-section">
        <div class="about-us-articles left-article">
          <h3>{{ txt.aboutUsTitle }}</h3>
          <p>{{ txt.aboutUsDescription }}</p>
          <p>{{ txt.aboutUsDescriptionP2 }}</p>
          <ul v-if="txt.aboutUsListDescriptionFirst">
            <li>{{ txt.aboutUsListDescriptionFirst }}</li>
            <li>{{ txt.aboutUsListDescriptionSecond }}</li>
            <li>{{ txt.aboutUsListDescriptionThird }}</li>
            <li>{{ txt.aboutUsListDescriptionFourth }}</li>
            <li>{{ txt.aboutUsListDescriptionFifth }}</li>
            <li>{{ txt.aboutUsListDescriptionSixth }}</li>
          </ul>
        </div>
        <div class="img-man-with-food">
          <img :src="manWithFoodImgSrc" alt="man-with-food-in-front-of-him" />
        </div>
      </div>

      <!-- OUR SERVICES SECTION WITH ICONS -->
      <div id="description">
        <div class="our-services-title">
          <h3>{{ txt.ourServicesTitle }}</h3>
        </div>

        <div class="our-services-section">
          <div class="icon-container">
            <img :src="restaurantMenuIconSrc" alt="restaurant-menu-icon" />
            <p class="paragraph-title">{{ txt.ourServicesParaTitleFirst }}</p>
            <p>{{ txt.ourServicesParaFirstDesc }}</p>
          </div>
          <div class="icon-container">
            <img :src="starIconSrc" alt="star-icon" />
            <p class="paragraph-title">
              {{ txt.ourServicesParaTitleSecond }}
            </p>
            <p>{{ txt.ourServicesParaSecondDesc }}</p>
          </div>
          <div class="icon-container">
            <img :src="chatBubbleIconSrc" alt="chat-bubble-outline-icon" />
            <p class="paragraph-title">{{ txt.ourServicesParaTitleThird }}</p>
            <p>{{ txt.ourServicesParaThirdDesc }}</p>
          </div>
        </div>
      </div>
      <!-- ABOUT US ARTICLES - COMPONENT -->
      <about-us-articles></about-us-articles>
    </div>
  </div>
</template>

<script>
import AboutUsArticles from '../components/AboutUsArticles.vue';
import HeroContainer from '../components/HeroContainer.vue';
import Spinner from '../components/Spinner.vue';
export default {
  components: { HeroContainer, AboutUsArticles, Spinner },
  name: 'UberUns',

  created() {
    fetch(`${process.env.MTK_CDN}/shop-about/about-texts.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.mainLogoImgSrc = `${cdnUrl}/${data.mainLogoImgPath}`;
        this.manWithFoodImgSrc = `${cdnUrl}/${data.manWithFoodImgPath}`;
        this.restaurantMenuIconSrc = `${cdnUrl}/${data.restaurantMenuIconPath}`;
        this.chatBubbleIconSrc = `${cdnUrl}/${data.chatBubbleIconPath}`;
        this.starIconSrc = `${cdnUrl}/${data.starIconPath}`;
        this.showSpinner = false;
      });
  },
  data() {
    return {
      txt: {},
      mainLogoImgSrc: '',
      manWithFoodImgSrc: '',
      restaurantMenuIconSrc: '',
      chatBubbleIconSrc: '',
      starIconSrc: '',
      showSpinner: true,
    };
  },
};
</script>

<style lang="scss">
@import '../scss/_about-page.scss';
</style>
