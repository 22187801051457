<template>
  <div class="cartside">
    <div v-if="hasCart()" class="sticky">
      <h4>Warenkorb</h4>
      <cart-table />
      <div class="cart-info">
        <router-link :to="{ path: 'checkout', query: { deliver_at: $parent.sale_date } }">
          <button>Checkout</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CartTable from './CartTable.vue';
export default {
  components: { CartTable },
  name: 'CartSide',
  methods: {
    hasCart() {
      if (!this.$root.$data.cartId) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.cart-info {
  padding: 20px 20px;
}

.close-icon {
  position: absolute;
  top: 2px;
  right: 5px;
}

/********RESPONSIVE DESIGN********/
@media (max-width: 800px) {
  .close-icon {
    position: absolute;
    top: 2px;
    right: 5px;
  }
  .cart-checkout {
    text-align: center;
  }
}
</style>
