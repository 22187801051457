<template>
  <div class="menu-container">
    <div class="menu-modules">
      <div class="module shadow" v-for="cat in categories" :key="cat.id">
        <a :href="'#' + cat.name">
          {{ cat.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
const Q = gql`
  {
    categories(where: {shop_type_id: {_eq: "1"}}) {
      id
      name
    }
  }
`;
export default {
  name: 'CategoryCssMenu',
  components: {},
  apollo: {
    categories: {
      query: Q,
    },
  },
  data() {
    return {
      categories: [],
    };
  },
};
</script>
