<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div class="parent" v-if="!showSpinner">
      <hero-image />
      <cantine-sticky-slide-menu />
      <div class="home-main" v-if="!$parent.showCart">
        <cantine-categories />
        <cart-side />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import CantineCategories from '../components/CantineCategories.vue';
import CantineStickySlideMenu from '../components/CantineStickySlideMenu.vue';
import CartSide from '../components/CartSide.vue';
import HeroImage from '../components/HeroImage.vue';
import Spinner from '../components/Spinner.vue';

export default {
  name: 'Kantine',
  components: { HeroImage, CantineStickySlideMenu, CantineCategories, CartSide, Spinner },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-home/images.json`)
      .then((response) => response.json())
      .then((data) => {
        this.$parent.showBottomBar = true;
        this.showSpinner = false;
      });
    const d = new Date();
    this.sale_date = dayjs(d.setDate(d.getDate() + 1)).format('YYYY-MM-DD');
  },

  data() {
    return {
      txt: {},
      showSpinner: true,
      sale_date: '2020-02-20',
    };
  },
};
</script>


