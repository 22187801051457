<template>
  <div class="categories-category-product" :key="product.id">

    <h4>{{ product.name }}</h4>
    <p>{{ product.body }}</p>
    <div class="allergene-flex">
      <div v-if="isOpen" class="allergene-box">
        <p class="allergene-title">Allergene</p>
        <span>{{ product.alergens }}</span>
      </div>
    </div>
    <button type="button" class="allergene-btn" @click="toggleAllergeneBox">
      {{ buttonCaption }}
    </button>
    <div class="buy">
      <button class="btn btn-sub" @click="delCartItem(product.id)">-</button>
      <h5 class="buy-price">{{ product.price }} €</h5>
      <button class="btn btn-add" @click="addItemToCart(product.id)">+</button>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { GET_CART_ITEMS } from './CartTable.vue';

const CREATE_CART = gql`
  mutation {
    insert_carts_one(object: {}) {
      id
    }
  }
`;

const ADD_CART_ITEM = gql`
  mutation addCartItem($cartID: uuid!, $productID: uuid) {
    insert_cart_items_one(object: { cart_id: $cartID, product_id: $productID }) {
      id
      quantity
      collect_at
      product {
        id
        name
        price
        alergens
        __typename
      }
    }
  }
`;

export const DEL_CART_ITEMS_BY_CART_ID_PRODUCT_ID = gql`
  mutation DelCartItemsByCartIdProductId($cart_id: uuid!, $product_id: uuid!) {
    delete_cart_items(where: { cart_id: { _eq: $cart_id }, product_id: { _eq: $product_id } }) {
      affected_rows
      returning {
        id
        product_id
      }
    }
  }
`;

export default {
  name: 'ShopProduct',
  props: {
    product: {
      type: Object,
    },
    image: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    buttonCaption() {
      return this.isOpen ? 'Schließen Allergenes' : 'Allergenes';
    },
  },
  methods: {
    toggleAllergeneBox() {
      this.isOpen = !this.isOpen;
    },
    addItemToCart(productId) {
      let cartId = this.findExistingCart();
      if (!cartId) {
        console.log('Creating cart...');
        this.createCart().then((data) => {
          cartId = data.data.insert_carts_one.id;
          console.log('Cart created: ' + cartId);
          this.$root.$data.cartId = cartId;
          localStorage.cartId = cartId;
          this.addCartItem(productId, cartId);
        });
      } else {
        console.log('Cart id: ' + cartId);
        this.$root.$data.cartId = cartId;
        this.addCartItem(productId, cartId);
      }
    },
    addCartItem(productId, cart_id) {
      console.log('Adding cart item with product id ' + productId);
      this.$apollo
        .mutate({
          mutation: ADD_CART_ITEM,
          variables: {
            cartID: cart_id,
            productID: productId,
          },
          update: (cache, { data: { insert_cart_items_one } }) => {
            try {
              const data = cache.readQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
              });
              const insProduct = {
                id: insert_cart_items_one.id,
                quantity: insert_cart_items_one.quantity,
                product: insert_cart_items_one.product,
                __typename: 'cart_items',
              };
              // console.log(insProduct);
              data.carts_by_pk.cart_items.push(insProduct);
              cache.writeQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
                data,
              });
            } catch (e) {
              console.log(e);
            }
          },
        })
        .then(() => console.log('Cart item added!'))
        .catch(() => {
          console.log(
            'Error adding cart item! Probably cart expired on the server. Cleaning up to create new one next time'
          );
          this.$root.$data.cartId = null;
          localStorage.removeItem('cartId');
        });
    },
    delCartItem(productId) {
      console.log('Deleting cart item with product id ' + productId);
      const cart_id = this.findExistingCart();
      this.$apollo
        .mutate({
          mutation: DEL_CART_ITEMS_BY_CART_ID_PRODUCT_ID,
          variables: {
            cart_id: cart_id,
            product_id: productId,
          },
          update: (cache, { data: { delete_cart_items } }) => {
            try {
              var data = cache.readQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
              });
              data.carts_by_pk.cart_items = data.carts_by_pk.cart_items.filter(function(i) {
                // TODO: Make this more smart and precise!
                return i.product.id !== delete_cart_items.returning[0].product_id;
              });
              cache.writeQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
                data,
              });
            } catch (e) {
              console.log(e);
            }
          },
        })
        .then(() => console.log('Cart item deleted!'));
    },
    createCart() {
      return this.$apollo.mutate({
        mutation: CREATE_CART,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import '@/scss/_allergenes.scss';

.buy {
  display: flex;
  justify-content: space-evenly;

  &-price {
    color: $mpc;
    font-weight: 500;
    font-size: 25px;
  }
}

.btn {
  &-add {
    background-color: $pl;
  }
  &-sub {
    background-color: $mi;
  }
  font-size: 1.8rem;
  height: 2.2rem;
  line-height: 1.8rem;
  padding: 0 1rem;
  margin-top: 0.9rem;
  padding-bottom: 2px;
  border: none;
}
</style>
