<template>
  <div
    class="profile-parent"
    :style="{
      background: createBackgroundCss,
    }"
  >
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div class="profile-container">
      <div class="profile-form">
        <div class="profile-btn-container">
          <button class="profile-btn" @click="$router.push('/myorders')">Bestellhistorie</button>
        </div>
        <div class="profile-title-container">
          <div class="profile-title">
            <h4>{{ txt.profileTitle }}</h4>
          </div>
        </div>
        <form class="form">
          <div class="profile-inputs">
            <div>
              <label for="firstName">{{ txt.firstName }}</label>
              <input id="firstName" type="text" :placeholder="$auth.user.given_name" required />
            </div>
            <div>
              <label for="eMail">{{ txt.eMail }}</label>
              <input id="eMail" type="email" :placeholder="$auth.user.email" required />
            </div>
            <div>
              <label for="city">{{ txt.city }}</label>
              <input id="city" type="text" placeholder="München" required />
            </div>
            <div>
              <label for="address">{{ txt.address }}</label>
              <input id="address" type="text" placeholder="Blubbweg 14" required />
            </div>
          </div>
          <div class="profile-inputs">
            <div class="second-column">
              <label for="lastName">{{ txt.lastName }}</label>
              <input id="lastName" type="text" :placeholder="$auth.user.family_name" required />
            </div>
            <div class="second-column">
              <label for="tel">{{ txt.tel }}</label>
              <input id="tel" type="text" placeholder="01779758121" required />
            </div>
            <div class="second-column">
              <label for="plz">{{ txt.plz }}</label>
              <input id="plz" type="text" placeholder="80000" required />
            </div>
            <div class="second-column">
              <label for="floor">{{ txt.floor }}</label>
              <input id="floor" type="text" placeholder="2" required />
            </div>
          </div>
        </form>
        <!-- <div class="profile-btn-container">
          <button class="profile-btn">{{ txt.btnTitle }}</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../components/Spinner.vue';
export default {
  components: { Spinner },
  name: 'ProfileInputsSection',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-my-profile/profile-texts.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.heroImgSrc = `${cdnUrl}/${data.heroImgBackgroundPath}`;
        this.showSpinner = false;
      });
  },
  data() {
    return {
      txt: {},
      heroImgSrc: '',
      showSpinner: true,
    };
  },
  computed: {
    createBackgroundCss() {
      return `linear-gradient(118.58deg, #ffffff 14.06%, rgba(255, 255, 255, 0) 55.73%),
    linear-gradient(90.56deg, #ffffff 29.17%, rgba(255, 255, 255, 0) 66.15%),
    url(\'${this.heroImgSrc}\')`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
@import '../scss/_profile-inputs.scss';
</style>
