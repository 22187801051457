<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div class="privacy-policy-container">
      <h3>{{ txt.privacyPolicyTitle }}</h3>
      <p>{{ txt.privacyPolicyText1 }}</p>
      <p>{{ txt.privacyPolicyText2 }}</p>
      <h4>{{ txt.privacyPolicyText3 }}</h4>
      <p>{{ txt.privacyPolicyText4 }}</p>
      <ul>
        <li>{{ txt.privacyPolicyText5 }}</li>
        <li>{{ txt.privacyPolicyText6 }}</li>
        <li>{{ txt.privacyPolicyText7 }}</li>
        <li>{{ txt.privacyPolicyText8 }}</li>
        <li>{{ txt.privacyPolicyText9 }}</li>
        <li>{{ txt.privacyPolicyText10 }}</li>
      </ul>
      <p>{{ txt.privacyPolicyText11 }}</p>
      <p>{{ txt.privacyPolicyText12 }}</p>
      <p>
        {{ txt.privacyPolicyText13 }}
        <a :href="txt.privacyPolicyTextLink13" class="link-break">{{ txt.privacyPolicyTextLink13 }}</a>
      </p>
      <h4>
        {{ txt.privacyPolicyText14 }}
      </h4>
      <h4>
        {{ txt.privacyPolicyText15 }}
      </h4>
      <p>
        {{ txt.privacyPolicyText16 }}
      </p>
      <p>
        {{ txt.privacyPolicyText17 }}
      </p>
      <ul>
        <li>{{ txt.privacyPolicyTextList18 }}</li>
        <li>{{ txt.privacyPolicyTextList19 }}</li>
        <li>{{ txt.privacyPolicyTextList20 }}</li>
        <li>{{ txt.privacyPolicyTextList21 }}</li>
      </ul>
      <p>
        {{ txt.privacyPolicyText22 }}
      </p>
      <h4>{{ txt.privacyPolicyText23 }}</h4>
      <p>{{ txt.privacyPolicyText24 }}</p>
      <h4>{{ txt.privacyPolicyText25 }}</h4>
      <p>{{ txt.privacyPolicyText26 }}</p>
      <h4>{{ txt.privacyPolicyText27 }}</h4>
      <p>{{ txt.privacyPolicyText28 }}</p>
      <h4>{{ txt.privacyPolicyText29 }}</h4>
      <p>{{ txt.privacyPolicyText30 }}</p>
      <h4>{{ txt.privacyPolicyText31 }}</h4>
      <h4>{{ txt.privacyPolicyText32 }}</h4>
      <p>{{ txt.privacyPolicyText33 }}</p>
      <h4>{{ txt.privacyPolicyText34 }}</h4>
      <p>{{ txt.privacyPolicyText35 }}</p>
      <p>{{ txt.privacyPolicyText36 }}</p>
      <h4>{{ txt.privacyPolicyText37 }}</h4>
      <p>{{ txt.privacyPolicyText38 }}</p>
      <h4>{{ txt.privacyPolicyText39 }}</h4>
      <p>{{ txt.privacyPolicyText40 }}</p>
      <h4>{{ txt.privacyPolicyText41 }}</h4>
      <p>{{ txt.privacyPolicyText42 }}</p>
      <h4>{{ txt.privacyPolicyText43 }}</h4>
      <h4>{{ txt.privacyPolicyText44 }}</h4>
      <p>{{ txt.privacyPolicyText45 }}</p>
      <p>{{ txt.privacyPolicyText46 }}</p>
      <p>{{ txt.privacyPolicyText47 }}</p>
      <h4>{{ txt.privacyPolicyText48 }}</h4>
      <p>{{ txt.privacyPolicyText49 }}</p>
      <p>{{ txt.privacyPolicyText50 }}</p>
      <h4>{{ txt.privacyPolicyText51 }}</h4>
      <p>{{ txt.privacyPolicyText52 }}</p>
      <h4>{{ txt.privacyPolicyText53 }}</h4>
      <p>{{ txt.privacyPolicyText54 }}</p>
      <h4>{{ txt.privacyPolicyText55 }}</h4>
      <p>{{ txt.privacyPolicyText56 }}</p>

      <h4>{{ txt.privacyPolicyText57 }}</h4>
      <h4>{{ txt.privacyPolicyText58 }}</h4>
      <p>{{ txt.privacyPolicyText59 }}</p>
      <h4>{{ txt.privacyPolicyText60 }}</h4>
      <p>{{ txt.privacyPolicyText61 }}</p>
      <p>{{ txt.privacyPolicyText62 }}</p>
      <p>{{ txt.privacyPolicyText63 }}</p>
      <h4>{{ txt.privacyPolicyText64 }}</h4>
      <p>{{ txt.privacyPolicyText65 }}</p>
      <h4>{{ txt.privacyPolicyText66 }}</h4>
      <p>{{ txt.privacyPolicyText67 }}</p>
      <h4>{{ txt.privacyPolicyText68 }}</h4>
      <p>{{ txt.privacyPolicyText69 }}</p>
      <h3>{{ txt.privacyPolicyText70 }}</h3>
      <h3>{{ txt.privacyPolicyText71 }}</h3>
      <p>{{ txt.privacyPolicyText72 }}</p>
      <p>{{ txt.privacyPolicyText73 }}</p>
      <h3>{{ txt.privacyPolicyText74 }}</h3>
      <p>{{ txt.privacyPolicyText75 }}</p>
      <h3>{{ txt.privacyPolicyText76 }}</h3>
      <p>{{ txt.privacyPolicyText77 }}</p>
      <h3>{{ txt.privacyPolicyText78 }}</h3>
      <p>{{ txt.privacyPolicyText79 }}</p>
      <h3>{{ txt.privacyPolicyText80 }}</h3>
      <p>
        {{ txt.privacyPolicyText81 }}
        <a :href="txt.privacyPolicyTextLink81">{{ txt.privacyPolicyTextLink81 }}</a>
      </p>
      <h3>{{ txt.privacyPolicyText82 }}</h3>
      <p>{{ txt.privacyPolicyText83 }}</p>
      <h3>{{ txt.privacyPolicyText84 }}</h3>
      <p>
        {{ txt.privacyPolicyText85 }}
        <a :href="txt.privacyPolicyTextLink85">{{ txt.privacyPolicyTextLinkName85 }}</a>
        {{ txt.privacyPolicyText86 }}
      </p>
      <h3>{{ txt.privacyPolicyText87 }}</h3>
      <p>{{ txt.privacyPolicyText88 }}</p>
      <h3>{{ txt.privacyPolicyText89 }}</h3>
      <h3>{{ txt.privacyPolicyText90 }}</h3>
      <p>{{ txt.privacyPolicyText91 }}</p>
      <p>
        {{ txt.privacyPolicyText92 }}
        <a :href="txt.privacyPolicyTextLink92">{{ txt.privacyPolicyTextLinkName92 }}</a>
        {{ txt.privacyPolicyText93 }}
        <a :href="txt.privacyPolicyTextLink94">{{ txt.privacyPolicyTextLinkName94 }}</a>
      </p>
      <h3>{{ txt.privacyPolicyText95 }}</h3>
      <p>{{ txt.privacyPolicyText96 }}</p>
      <h3>{{ txt.privacyPolicyText97 }}</h3>
      <p>{{ txt.privacyPolicyText98 }}</p>
      <p>{{ txt.privacyPolicyText99 }}</p>
      <h3>{{ txt.privacyPolicyText100 }}</h3>
      <p>{{ txt.privacyPolicyText101 }}</p>
      <h3>{{ txt.privacyPolicyText102 }}</h3>
      <p>
        {{ txt.privacyPolicyText103 }}
        <a :href="txt.privacyPolicyTextLink103">{{ txt.privacyPolicyTextLinkName103 }}</a>
      </p>
      <h3>{{ txt.privacyPolicyText104 }}</h3>
      <p>{{ txt.privacyPolicyText105 }}</p>
      <h3>{{ txt.privacyPolicyText106 }}</h3>
      <p>{{ txt.privacyPolicyText107 }}</p>
      <h3>{{ txt.privacyPolicyText108 }}</h3>
      <p>{{ txt.privacyPolicyText109 }}</p>
      <h3>{{ txt.privacyPolicyText110 }}</h3>
      <p>{{ txt.privacyPolicyText111 }}</p>
      <p>{{ txt.privacyPolicyText112 }}</p>
      <h4>{{ txt.privacyPolicyText113 }}</h4>
      <p>{{ txt.privacyPolicyText114 }}</p>
      <h4>{{ txt.privacyPolicyText115 }}</h4>
      <p>{{ txt.privacyPolicyText116 }}</p>
      <p>{{ txt.privacyPolicyText117 }}</p>
    </div>
  </div>
</template>

<script>
import Spinner from '../components/Spinner.vue';

export default {
  components: { Spinner },
  name: 'Datenschutzerklärung',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-privacy-policy/privacy-policy.json`)
      .then((response) => response.json())
      .then((data) => {
        this.txt = data;
        this.showSpinner = false;
      });
  },
  data() {
    return {
      txt: {},
      showSpinner: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
@import '@/scss/_privacy-policy.scss';
</style>
