<template>
  <div class="payment-container">
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div>
      <div class="icon-container-payment">
        <i class="fa fa-times-circle"></i>
      </div>
      <h1 class="payment-failed">{{ txt.paymentFailed }}</h1>
      <h3 class="payment-desc">{{ txt.paymentDesc }}</h3>
      <button type="button" @click="$router.push('/')">{{ txt.paymentBtn }}</button>
    </div>
  </div>
</template>

<script>
import Spinner from '../components/Spinner.vue';

export default {
  name: 'OrderFail',
  components: { Spinner },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-order-fail/order-fail.json`)
      .then((response) => response.json())
      .then((data) => {
        this.txt = data;
        this.showSpinner = false;
      });
  },
  data() {
    return {
      txt: {},
      showSpinner: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
@import '@/scss/_order-fail.scss';
</style>
