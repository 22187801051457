<template>
  <div class="cart-table-container">
    <span class="no-products" v-if="!hasProduct()">keine Produkte</span>
    <div v-if="hasProduct()">
      <table>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Anzahl</th>
            <th scope="col">Preis</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in carts_by_pk.cart_items" :key="index">
            <td>
              {{ item.product.name }}
              <strong>
                <span v-if="item.collect_at">-</span>
                <i v-if="item.collect_at">{{ item.collect_at | deDateTime }}</i></strong
              >
            </td>
            <td>
              <plus-minus
                @delete="delCartItem($event, item.id)"
                @update:qty="updateQty($event, item.id)"
                :qty.sync="item.quantity"
              />
            </td>
            <td>{{ item.product.price }}</td>
            <td>{{ priceMmultiply(item.product.price, item.quantity) }}€</td>
          </tr>
          <tr>
            <td><strong>Summe</strong></td>
            <td></td>
            <td></td>
            <td>
              <strong>{{ totalPrice() }}€</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import currency from 'currency.js';
import PlusMinus from './PlusMinus.vue';

export const GET_CART_ITEMS = gql`
  query getCartItemsByCartId($cartID: uuid!) {
    carts_by_pk(id: $cartID) {
      cart_items {
        id
        quantity
        collect_at
        product {
          id
          name
          price
        }
      }
    }
  }
`;

export const UPDATE_CART_ITEM_QTY = gql`
  mutation updateQty($quantity: Int!, $id: uuid!) {
    update_cart_items_by_pk(pk_columns: { id: $id }, _set: { quantity: $quantity }) {
      id
      quantity
      collect_at
      __typename
    }
  }
`;

export const DEL_CART_ITEM_BY_ID = gql`
  mutation DelCartItemById($id: uuid!) {
    delete_cart_items_by_pk(id: $id) {
      id
    }
  }
`;

export default {
  components: { PlusMinus },
  name: 'CartTable',
  data() {
    return {
      foo: {},
    };
  },
  methods: {
    hasProduct() {
      if (!this.carts_by_pk) {
        return false;
      }
      return this.carts_by_pk.cart_items.length > 0;
    },
    priceMmultiply(price, m) {
      return currency(price).multiply(m);
    },
    totalPrice() {
      return this.carts_by_pk.cart_items
        .map((x) => currency(x.product.price).multiply(x.quantity))
        .reduce((t, n) => currency(t).add(n));
    },
    updateQty(event, id) {
      // console.log(event, id);
      this.$apollo
        .mutate({
          mutation: UPDATE_CART_ITEM_QTY,
          variables: {
            id: id,
            quantity: event,
          },
        })
        .then(() => console.log('Quantity changed on the server!'));
    },
    delCartItem(event, id) {
      // console.log(event, id);
      this.$apollo
        .mutate({
          mutation: DEL_CART_ITEM_BY_ID,
          variables: {
            id: id,
          },
          update: (cache, { data: { delete_cart_items_by_pk } }) => {
            try {
              var data = cache.readQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: this.$root.$data.cartId,
                },
              });
              data.carts_by_pk.cart_items = data.carts_by_pk.cart_items.filter(function(i) {
                // TODO: Make this more smart and precise!
                return i.id !== delete_cart_items_by_pk.id;
              });
              cache.writeQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: this.$root.$data.cartId,
                },
                data,
              });
            } catch (e) {
              console.log(e);
            }
          },
        })
        .then(() => console.log(`Cart Item: ${id} deleted!`));
    },
  },
  apollo: {
    carts_by_pk: {
      query: GET_CART_ITEMS,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          cartID: this.$root.$data.cartId,
        };
      },
      skip() {
        return !this.$root.$data.cartId;
      },
    },
  },
};
</script>

<style>
.cart-table-container {
  padding: 50px;
  text-align: center;
}

.no-products {
  margin-top: 20px;
  padding: 8px;
}

/********RESPONSIVE DESIGN********/
@media (max-width: 800px) {
  .cart-table-container {
    text-align: center;
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
    text-align: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .cart-table-container {
    padding: 40px 10px 10px;
  }

  table {
    display: inline-table !important;
  }

  td,
  th {
    padding: 0;
  }
  th {
    padding-right: 6px;
  }
}
</style>
