<template>
  <div>
    <profile-inputs-section />
  </div>
</template>

<script>
import ProfileInputsSection from '../components/ProfileInputsSection.vue';
export default {
  name: 'MyProfile',
  components: {
    ProfileInputsSection,
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
@import '../scss/_profile-inputs.scss';
</style>
