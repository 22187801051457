<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <!-- CONTACT HERO CONTAINER -->
    <div
      class="contact-hero-container"
      :style="{
        background: createBackgroundCss,
      }"
    >
      <div class="contact-text-container">
        <img class="logo-icon" :src="mainLogoImgSrc" alt="shop-logo" />
        <h2 class="contact-hero-title">{{ txt.contactHeroTitle }}</h2>
        <div class="contact-para-hero-container">
          <p>{{ txt.contactParaHeroContainer }}</p>
        </div>
      </div>
    </div>
    <!-- MAIN SECTION -->
    <div class="main-section-wrapper">
      <!-- CONTACT BAR-->
      <div class="contact-bar">
        <div class="icons-and-paras-wrapper">
          <div>
            <div>
              <img :src="locationImgSrc" alt="location icon" />
            </div>
            <div>
              <p>{{ txt.location }}</p>
            </div>
          </div>

          <div>
            <div>
              <img :src="mailImgSrc" alt="email-icon" />
            </div>
            <div>
              <p>{{ txt.email }}</p>
            </div>
          </div>

          <div>
            <div>
              <img :src="speakerPhoneImgSrc" alt="phone-speaker-icon" />
            </div>
            <div>
              <p>{{ txt.phone }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- MAP LOCATION IMG -->
      <div>
        <div class="map-input-container">
          <div class="map-img">
            <a :href="txt.mapLink">
              <img :src="mapImgSrc" alt="google-maps-location" />
            </a>
          </div>

          <!-- INPUT FIELD -->
          <form @submit.prevent="submitForm">
            <div class="form-control">
              <label id="user-name-label" for="user-name">
                <input
                  id="user-name"
                  name="user-name"
                  type="text"
                  v-model="txt.userName"
                  placeholder="Name"
                  required
              /></label>
            </div>
            <div class="form-control">
              <label id="email-label" for="email">
                <input
                  id="email"
                  name="email"
                  type="email"
                  v-model="txt.userEmail"
                  placeholder="E-mail"
                  required
              /></label>
            </div>
            <div class="form-control">
              <label id="description-label" for="description">
                <textarea
                  id="description"
                  name="description"
                  type="text"
                  v-model="txt.userDescription"
                  ref="ageInput"
                  placeholder="Nachricht"
                  required
                >
                </textarea
              ></label>
            </div>
            <div class="error-message" v-if="error" @click="error = false">
              <i class="fa fa-exclamation-circle"></i>{{ errorMessage }}
            </div>
            <div class="success-message" v-if="success" @click="success = false">
              <i class="fa fa-check-circle"></i>{{ txt.successMessage }}
            </div>
            <div class="input-btn-container">
              <button class="input-btn">{{ txt.btn }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- CONTACT INFO SECTION-->
    <div class="contact-info-main-container">
      <div class="wrapper">
        <div class="contact-info-title">
          <h3>{{ txt.contactInfoTitle }}</h3>
        </div>

        <div class="contact-info-main">
          <div class="contact-main">
            <div class="contact-info-paras">
              <div class="contact-info-company-paras">
                <p class="contact-info-para-title">
                  {{ txt.contactInfoParaTitle }}
                </p>
                <p>{{ txt.contactInfoParaSubtitle }}</p>
              </div>
              <div class="contact-info-details-paras">
                <p>{{ txt.address }}</p>
                <p>{{ txt.tel }}</p>
                <p>{{ txt.fax }}</p>
                <p>{{ txt.eMail }}</p>
              </div>
            </div>
          </div>

          <div class="management-container">
            <div class="management-paras">
              <p id="managementTitle">
                {{ txt.managementTitle }}
              </p>
              <p>{{ txt.managementPosition }}</p>
              <p>{{ txt.managementMobile }}</p>
              <p>{{ txt.managementEmail }}</p>
            </div>

            <div class="management-person-paras">
              <p>{{ txt.managementPersonName }}</p>
              <p>{{ txt.managementPersonMobile }}</p>
              <p>{{ txt.managementPersonEmail }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Spinner from '../components/Spinner.vue';

const INSERT_MESSAGE = gql`
  mutation InsertMessage($name: String!, $email: String!, $message: String!) {
    insert_contact_messages(objects: { email: $email, message: $message, name: $name }) {
      affected_rows
    }
  }
`;
export default {
  components: { Spinner },
  name: 'Kontakt',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-contact/contact.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.mainLogoImgSrc = `${cdnUrl}/${data.mainLogoImgPath}`;
        this.mapImgSrc = `${cdnUrl}/${data.mapImgPath}`;
        this.heroImgSrc = `${cdnUrl}/${data.heroImgPath}`;
        this.locationImgSrc = `${cdnUrl}/${data.locationImgPath}`;
        this.mailImgSrc = `${cdnUrl}/${data.mailImgPath}`;
        this.speakerPhoneImgSrc = `${cdnUrl}/${data.speakerPhoneImgPath}`;
        if (this.$auth.user) {
          this.txt.userEmail = this.$auth.user.email;
          this.txt.userName = this.$auth.user.name;
        }
        this.showSpinner = false;
      });
  },
  data() {
    return {
      txt: {},
      mainLogoImgSrc: '',
      mapImgSrc: '',
      heroImgSrc: '',
      locationImgSrc: '',
      mailImgSrc: '',
      speakerPhoneImgSrc: '',
      error: false,
      success: false,
      errorMessage: '',
      showSpinner: true,
    };
  },
  computed: {
    createBackgroundCss() {
      return `linear-gradient(118.58deg, #ffffff 14.06%, rgba(255, 255, 255, 0) 55.73%),
    linear-gradient(90.56deg, #ffffff 29.17%, rgba(255, 255, 255, 0) 66.15%),
    url(\'${this.heroImgSrc}\')`;
    },
  },
  methods: {
    submitMessage() {
      return this.$apollo.mutate({
        mutation: INSERT_MESSAGE,
        variables: {
          name: this.txt.userName,
          email: this.txt.userEmail,
          message: this.txt.userDescription,
        },
      });
    },
    submitForm() {
      this.submitMessage()
        .then((response) => {
          console.log(response);
          this.success = true;
        })
        .catch((error) => {
          console.error({ error });
          this.error = true;
          this.errorMessage = error.message;
        });
      this.txt.userName = null;
      this.txt.userEmail = null;
      this.txt.userDescription = null;
      this.error = false;
      this.errorMessage = '';
      this.success = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_contact-page.scss';
</style>
