<template>
  <div>
    <!-- SPINNER -->
    <spinner :start="showSpinner" />
    <div
      class="hero-my-orders"
      :style="{
        background: createBackgroundCss,
      }"
    ></div>
    <h6 class="statusBarTitle">{{ txt.statusBarTitle }}</h6>

    <div class="flex-container">
      <order-progress />
      <h6 class="contactUsTitle">{{ txt.contactUsTitle }}</h6>
      <button class="contactBtnTitle" type="button" @click="$router.push('/kontakt')">
        {{ txt.contactBtnTitle }}
      </button>
    </div>
    <div class="table-container cart-table-container">
      <h3 class="order-history">{{ txt.orderHistory }}</h3>
      <table>
        <thead>
          <tr>
            <th scope="col">{{ txt.date }}</th>
            <th scope="col">{{ txt.orderFor }}</th>
            <th scope="col">{{ txt.delivery }}</th>
            <th scope="col">{{ txt.price }}</th>
          </tr>
        </thead>
        <tbody id="tableBody">
          <tr v-for="(o, index) in orders" :key="index">
            <td>{{ o.created_at | deDateTime }}</td>
            <td v-if="o.order_items && o.order_items[0].collect_at">{{ o.order_items[0].collect_at | deDateTime }}</td>
            <td v-else>Jetzt</td>
            <td>
              <a
                href="#tableBody"
                class="detailsLink"
                @click="$router.push(`/ordersuccess?order=${o.id}`)"
                >{{ o.product_names }}</a
              >
            </td>

            <td>{{ o.total_price }}€</td>
            <div class="details-btn-container">
              <button
                class="details-btn"
                type="button"
                @click="$router.push(`/ordersuccess?order=${o.id}`)"
              >
                {{ txt.details }}
              </button>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import OrderProgress from '../components/OrderProgress.vue';
import Spinner from '../components/Spinner.vue';

const GET_ORDERS = gql`
  query myOrders {
    orders(order_by: { created_at: desc }) {
      id
      confirmed
      product_names
      base_price
      vat
      total_price
      created_at
      type
      status
      hour
      deliver_at
      order_items {
        price
        collect_at
        quantity
        product {
          name
        }
      }
    }
  }
`;

export default {
  name: 'MyOrders',
  components: {
    OrderProgress,
    Spinner,
  },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-my-orders/my-orders-texts.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.heroImgSrc = `${cdnUrl}/${data.heroImgPath}`;
        this.showSpinner = false;
      });
  },
  data() {
    return {
      txt: {},
      orders: [{ status: 'broken', type: 'for-delivery' }],
      heroImgSrc: '',
      showSpinner: true,
    };
  },
  computed: {
    createBackgroundCss() {
      return `url(\'${this.heroImgSrc}\')`;
    },
  },
  apollo: {
    orders: {
      query: GET_ORDERS,
      // pollInterval: 3000,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_order-progress.scss';
@import '@/scss/_my-orders.scss';
@import '../scss/_variables.scss';
</style>
