<template>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
import { createProvider } from '../vue-apollo';

export default {
  created() {
    this.$parent.showRv = false
  },
  destroyed() {
    if (this.$auth.isAuthenticated) {
      this.$parent.$apolloProvider = createProvider();
    }
    this.$parent.showRv = true
  },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';
.lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180px;
  height: 180px;
  margin-top: -90px;
  margin-left: -100px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 164px;
  height: 164px;
  margin: 18px;
  border: 18px solid $bl;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $rbc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
