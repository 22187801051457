import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import { createAnonProvider } from './vue-apollo';
import './mixins/cartFinder.js';

import dayjs from 'dayjs';
import 'dayjs/locale/de';

// Vue.prototype.$dayjs = dayjs;
Vue.filter('deDateTime', (value) => {
  return dayjs(value)
    .locale('de')
    .format('DD/MMMM/YYYY - HH:mm');
});

Vue.filter('deDate', (value) => {
  return dayjs(value)
    .locale('de')
    .format('dd DD/MM');
});

Vue.filter('deLongDate', (value) => {
  return dayjs(value)
    .locale('de')
    .format('dddd DD/MM');
});

const routes = [{ path: '/', component: () => import(`./pages/${FF_HOME_PAGE}.vue`) }];

// Import the plugin here
import { Auth0Plugin } from './auth';
const pages = require.context('./pages/', false, /\.vue$/i);
pages.keys().map((key) =>
  routes.push({
    path: key.split('.')[1].toLowerCase(),
    component: pages(key).default,
  })
);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.config.productionTip = false;

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
});

var store = {
  cartId: null,
};

new Vue({
  el: '#app',
  router,
  apolloProvider: createAnonProvider(),
  data: store,
  render: (h) => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'));
  },
});
