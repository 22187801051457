<template>
  <div class="cantine-category-product-wrapper">
    <img v-if="product.img_url" :src="product.img_url" :alt="product.name" />
    <img
      v-else-if="!product.img_url"
      src="../assets/img-coming-soon.jpg"
      class="img-coming-soon"
      alt="image-coming-soon"
    />
    <div class="cantine-category-product" :key="product.id">
      <div class="cantine-category-product-name">
        <h4>{{ product.name }}</h4>
      </div>
      <p>{{ product.body }}</p>
      <div class="allergene-flex">
        <div v-if="isOpen" class="allergene-box">
          <p class="allergene-title">Allergene</p>
          <span>{{ product.alergens }}</span>
        </div>
      </div>
      <button type="button" class="allergene-btn" @click="toggleAllergeneBox">
        {{ buttonCaption }}
      </button>
      <div class="buy">
        <button class="btn btn-sub" @click="delCartItem(product.id)">-</button>
        <h5 class="buy-price">{{ product.price }} €</h5>
        <button class="btn btn-add" @click="addItemToCart(product.id)">+</button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { GET_CART_ITEMS } from './CartTable.vue';
import dayjs from 'dayjs';

const CREATE_CART = gql`
  mutation {
    insert_carts_one(object: {}) {
      id
    }
  }
`;

const ADD_CART_ITEM = gql`
  mutation addCartItem($item_object: cart_items_insert_input!) {
    insert_cart_items_one(object: $item_object) {
      id
      quantity
      collect_at
      product {
        id
        name
        price
        alergens
        img_url
        __typename
      }
    }
  }
`;

export const DEL_CART_ITEMS_BY_CART_ID_PRODUCT_ID = gql`
  mutation DelCartItemsByCartIdProductId($cart_id: uuid!, $product_id: uuid!) {
    delete_cart_items(where: { cart_id: { _eq: $cart_id }, product_id: { _eq: $product_id } }) {
      affected_rows
      returning {
        id
        product_id
      }
    }
  }
`;

export default {
  name: 'CantineProduct',
  props: {
    product: {
      type: Object,
    },
    image: {
      type: String,
    },
    hour: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    buttonCaption() {
      return this.isOpen ? 'Allergene schließen' : 'Allergene';
    },
  },
  methods: {
    toggleAllergeneBox() {
      this.isOpen = !this.isOpen;
    },
    addItemToCart(productId) {
      let cartId = this.findExistingCart();
      if (!cartId) {
        console.log('Creating cart...');
        this.createCart().then((data) => {
          cartId = data.data.insert_carts_one.id;
          console.log('Cart created: ' + cartId);
          this.$root.$data.cartId = cartId;
          localStorage.cartId = cartId;
          this.addCartItem(productId, cartId);
        });
      } else {
        console.log('Cart id: ' + cartId);
        this.$root.$data.cartId = cartId;
        this.addCartItem(productId, cartId);
      }
    },
    addCartItem(product_id, cart_id) {
      const formatHour = this.hour.split(':');
      const getHour = formatHour[0];
      const getMinutes = formatHour[1].slice(0, 2);
      const collectDateHour = dayjs(this.$parent.$parent.sale_date)
        .set('hour', getHour)
        .set('minute', getMinutes);
      console.log('Adding cart item with product id ' + product_id);
      this.$apollo
        .mutate({
          mutation: ADD_CART_ITEM,
          variables: {
            item_object: {
              cart_id,
              product_id,
              collect_at: collectDateHour,
            },
          },
          update: (cache, { data: { insert_cart_items_one } }) => {
            try {
              const data = cache.readQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
              });
              const insProduct = {
                id: insert_cart_items_one.id,
                quantity: insert_cart_items_one.quantity,
                collect_at: insert_cart_items_one.collect_at,
                product: insert_cart_items_one.product,
                __typename: 'cart_items',
              };
              // console.log(insProduct);
              data.carts_by_pk.cart_items.push(insProduct);
              cache.writeQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
                data,
              });
            } catch (e) {
              console.log(e);
            }
          },
        })
        .then(() => console.log('Cart item added!'))
        .catch(() => {
          console.log(
            'Error adding cart item! Probably cart expired on the server. Cleaning up to create new one next time'
          );
          this.$root.$data.cartId = null;
          localStorage.removeItem('cartId');
        });
    },
    delCartItem(productId) {
      console.log('Deleting cart item with product id ' + productId);
      const cart_id = this.findExistingCart();
      this.$apollo
        .mutate({
          mutation: DEL_CART_ITEMS_BY_CART_ID_PRODUCT_ID,
          variables: {
            cart_id: cart_id,
            product_id: productId,
          },
          update: (cache, { data: { delete_cart_items } }) => {
            try {
              var data = cache.readQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
              });
              data.carts_by_pk.cart_items = data.carts_by_pk.cart_items.filter(function(i) {
                // TODO: Make this more smart and precise!
                return i.product.id !== delete_cart_items.returning[0].product_id;
              });
              cache.writeQuery({
                query: GET_CART_ITEMS,
                variables: {
                  cartID: cart_id,
                },
                data,
              });
            } catch (e) {
              console.log(e);
            }
          },
        })
        .then(() => console.log('Cart item deleted!'));
    },
    createCart() {
      return this.$apollo.mutate({
        mutation: CREATE_CART,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_cantine-product.scss';
</style>
