<template>
  <div class="box">
    <div class="close-icon" @click="$parent.toggleCart">
      <i class="fa fa-times fa-2x"></i>
    </div>
    <cart-table />
    <div class="cart-checkout" @click="$parent.toggleCart">
      <router-link to="/checkout">CHECKOUT</router-link>
    </div>
  </div>
</template>

<script>
import CartTable from './CartTable.vue';
export default {
  name: 'CartPopdown',
  components: { CartTable },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.box {
  user-select: none;
  width: auto;
  height: auto;
  background-color: $cpbc;
  box-sizing: border-box;
  right: 0;
  padding: 5px;
  position: absolute;
  z-index: 35;
  padding-bottom: 50px;
}
.close-icon {
  position: absolute;
  top: 2px;
  right: 5px;
}

.cart-checkout {
  padding-bottom: 50px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.cart-checkout::after {
  font-size: 2rem;
  font-weight: 900;
}

//********RESPONSIVE DESIGN********//
@media (max-width: 800px) {
  .box {
    width: 100%;
    padding-top: 10px;
    min-height: 100vh;
    margin: 0;
  }
  .close-icon {
    position: absolute;
    top: 2px;
    right: 5px;
    cursor: pointer;
  }
  .cart-checkout {
    text-align: center;
  }
}
</style>
