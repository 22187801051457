<template>
  <div id="description">
    <div class="ordering-process-title">
      <h4>{{ txt.orderingProcessTitle }}</h4>
    </div>
    <div class="delivery-icons">
      <!-- FOOD-SERVICE-ICON -->
      <div class="icon-and-para-wrapper">
        <div class="icon-container food-service">
          <img :src="serviceFoodIconSrc" alt="service food in a restaurant" />
        </div>
        <div class="para-container">
          <p class="para-title">{{ txt.deliveryFoodServiceTitle }}</p>
          <p class="para-desc">{{ txt.deliveryFoodServicePara }}</p>
        </div>
      </div>
      <!-- STYLISH-LINE-DIV -->
      <div>
        <div class="small-line-div"></div>
      </div>

      <!-- TRUCK-DELIVERY-ICON -->
      <div class="icon-and-para-wrapper">
        <div class="icon-container truck">
          <img :src="deliveryTruckIconSrc" alt="fast truck for food delivery" />
        </div>
        <div class="para-container">
          <p class="para-title">{{ txt.deliveryTruckTitle }}</p>
          <p class="para-desc">{{ txt.deliveryTruckPara }}</p>
        </div>
      </div>
      <!-- STYLISH-LINE-DIV -->
      <div>
        <div class="small-line-div"></div>
      </div>
      <!-- TV-ICON -->
      <div class="icon-and-para-wrapper">
        <div class="icon-container tv">
          <img :src="tvMonitorIconSrc" alt="vintage tv monitor" />
        </div>
        <div class="para-container">
          <p class="para-title">{{ txt.tvTitle }}</p>
          <p class="para-desc">{{ txt.tvPara }}</p>
        </div>
      </div>
      <!-- STYLISH-LINE-DIV -->
      <div>
        <div class="small-line-div"></div>
      </div>
      <!-- UTENSIL ICON -->
      <div class="icon-and-para-wrapper">
        <div class="icon-container utensil">
          <img :src="utensilIconSrc" alt="plate, fork and knife" />
        </div>
        <div class="para-container">
          <p class="para-title">{{ txt.utensilTitle }}</p>
          <p class="para-desc">{{ txt.utensilPara }}</p>
        </div>
      </div>
    </div>
    <div class="delivery-banks">
      <p>{{ txt.paymentMethodFirst }}</p>
      <p>{{ txt.paymentMethodSecond }}</p>
      <p>{{ txt.paymentMethodThird }}</p>
      <p>{{ txt.paymentMethodFourth }}</p>
      <div class="icon-banks-container">
        <div>
          <img :src="mastercardIconSrc" alt="mastercard logo" />
        </div>
        <div>
          <img :src="paypalIconSrc" alt="paypal logo" />
        </div>
        <div>
          <img :src="visaIconSrc" alt="visa card logo" />
        </div>
        <div>
          <img :src="stripeIconSrc" alt="stripe logo" />
        </div>
        <div>
          <img :src="checkoutIconSrc" alt="2co checkout logo" />
        </div>
      </div>
      <p>{{ txt.paymentMethodFifth }}</p>
      <p>{{ txt.paymentMethodSixth }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeliveryOrderingSection',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-delivery/delivery-texts-ordering-section.json`)
      .then((response) => response.json())
      .then((data) => {
        const cdnUrl = process.env.MTK_CDN;
        this.txt = data;
        this.serviceFoodIconSrc = `${cdnUrl}/${data.serviceFoodIconPath}`;
        this.deliveryTruckIconSrc = `${cdnUrl}/${data.deliveryTruckIconPath}`;
        this.tvMonitorIconSrc = `${cdnUrl}/${data.tvMonitorIconPath}`;
        this.utensilIconSrc = `${cdnUrl}/${data.utensilIconPath}`;
        this.mastercardIconSrc = `${cdnUrl}/${data.mastercardIconPath}`;
        this.paypalIconSrc = `${cdnUrl}/${data.paypalIconPath}`;
        this.visaIconSrc = `${cdnUrl}/${data.visaIconPath}`;
        this.stripeIconSrc = `${cdnUrl}/${data.stripeIconPath}`;
        this.checkoutIconSrc = `${cdnUrl}/${data.checkoutIconPath}`;
      });
  },
  data() {
    return {
      txt: {},
      serviceFoodIconSrc: '',
      deliveryTruckIconSrc: '',
      tvMonitorIconSrc: '',
      utensilIconSrc: '',
      mastercardIconSrc: '',
      paypalIconSrc: '',
      visaIconSrc: '',
      stripeIconSrc: '',
      checkoutIconSrc: '',
    };
  },
};
</script>

<style lang="scss">
@import '../scss/_delivery-page.scss';
@import '@/scss/_variables.scss';

.ordering-process-title h4 {
  font-weight: 400;
  line-height: 46.88px;
  font-size: 40px;
  text-align: center;
  color: $bg;
  padding-bottom: 25px;
}
//  - SECTIONS WITH ICONS

.delivery-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-and-para-wrapper {
    width: 100%;
    .icon-container {
      border-radius: 50%;
      padding: 15px;
      border: none;
      background-color: $lg;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      img {
        width: 60px;
      }
    }
    .para-container {
      color: $bg;
      text-align: center;
      .para-title {
        font-weight: 400;
        font-size: normal;
        font-size: 20px;
        line-height: 23.44px;
        margin-bottom: 3px;
        margin-top: 20px;
      }
      .para-desc {
        font-size: 1.175rem;
        font-weight: 300;
        line-height: 1.375rem;
        letter-spacing: 0.0071428571em;
      }
    }
  }

  .small-line-div {
    background-color: $bg;
    width: 50px;
    height: 5px;
    margin-bottom: 100px;
  }
}

.delivery-banks {
  width: 71%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 100px;

  p {
    color: $bg;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    font-style: normal;
  }

  .icon-banks-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 90px;
    }
  }
}
</style>
