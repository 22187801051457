<template>
  <div class="table-container cart-table-container">
    <h3 class="currentOrdersTitle">{{ txt.yourOrder }}</h3>
    <table>
      <thead>
        <tr>
          <th>{{ txt.anzahl }}</th>
          <th>{{ txt.produkt }}</th>
          <th>{{ txt.orderFor }}</th>
          <th>{{ txt.preis }}</th>
          <th>{{ txt.total }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in orders[0].order_items" :key="index">
          <td>{{ item.product.name }}</td>
          <td>{{ item.quantity }} x</td>
          <td v-if="item.collect_at">{{ item.collect_at | deDateTime }}</td>
          <td v-if="!item.collect_at">Jetzt</td>
          <td>{{ item.price }}€</td>
          <td>{{ priceMmultiply(item.price, item.quantity) }}€</td>
        </tr>
        <tr>
          <td><strong>Grundpreis</strong></td>
          <td></td>
          <td></td>
          <td>{{ orders[0].base_price }}€</td>
        </tr>
        <tr>
          <td><strong>7% MwSt.</strong></td>
          <td></td>
          <td></td>
          <td>{{ orders[0].vat }}€</td>
        </tr>
        <tr>
          <td><strong>Zwischensumme</strong></td>
          <td></td>
          <td></td>
          <td>{{ orders[0].total_price }}€</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import currency from 'currency.js';

const GET_ORDER = gql`
  query myOrder($id: uuid!) {
    orders(where: { id: { _eq: $id } }) {
      confirmed
      product_names
      base_price
      vat
      total_price
      deliver_at
      order_items {
        price
        collect_at
        quantity
        product {
          name
        }
      }
    }
  }
`;
export default {
  name: 'CurrentOrdersTable',
  apollo: {
    orders: {
      query: GET_ORDER,
      variables() {
        return {
          // id: 'ed45051e-d9cd-4c51-a38c-addd81450024',
          id: this.$route.query.order,
        };
      },
      // pollInterval: 3000,
    },
  },
  created() {
    fetch(`${process.env.MTK_CDN}/shop-order-success/order-success.json`)
      .then((response) => response.json())
      .then((data) => {
        this.txt = data;
      });
  },
  data() {
    return {
      txt: {},
      orders: ['hardcoded'],
    };
  },
  methods: {
    priceMmultiply(price, m) {
      return currency(price).multiply(m);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/_variables.scss';
.table-container .cart-table-container {
  padding-bottom: 120px;
}
.currentOrdersTitle {
  padding-bottom: 50px;
}

//********RESPONSIVE DESIGN********//
@media only screen and (max-width: 1124px) {
  .table-container {
    // font-size: 15px;
    flex: unset;
  }
}

@media screen and (min-width: 640px) and (max-width: 820px) {
  .image-and-table-main-container .cart-table-container {
    padding: 10px 10px;
  }
  .cart-table-container {
    font-size: 16px;
    padding: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .cart-table-container {
    font-size: 13px;
    padding: 10px 10px;

    :first-child {
      padding: 4px;
    }
    :nth-child(2) {
      padding: 4px;
    }
    :nth-child(3) {
      padding: 4px;
    }
    :last-child {
      padding: 4px;
    }
  }

  table {
    display: inline-table !important;
  }

  td,
  th {
    padding: 0;
  }
}

@media only screen and (max-width: 425px) {
  .cart-table-container {
    font-size: 10px;
    padding: 5px 5px;

    :first-child {
      padding: 2px;
    }
    :nth-child(2) {
      padding: 2px;
    }
    :nth-child(3) {
      padding: 2px;
    }
    :last-child {
      padding: 2px;
    }
  }
}
</style>
