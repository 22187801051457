<template>
  <div class="order-progress-parent">
    <div class="progress">
      <ul v-if="$parent.orders[0].type">
        <li
          v-for="item in order_items[$parent.orders[0].type]"
          :key="item.message"
          :class="{
            active: item.active_for.includes($parent.orders[0].status),
            broken: item.broken_for.includes($parent.orders[0].status),
          }"
        >
          <img :src="getImgUrl(item.imgIconPath)" :alt="item.alt"/>
          <p>{{ item.message }}</p>
          <i
            class="fa"
            :class="{
              'fa-check': item.active_for.includes($parent.orders[0].status),
              'fa-times': item.broken_for.includes($parent.orders[0].status),
            }"
          ></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderProgress',
  created() {
    fetch(`${process.env.MTK_CDN}/shop-order-progress/order-progress.json`)
      .then((response) => response.json())
      .then((data) => {
        this.txt = data;
      });
  },
  data() {
    return {
      txt: {},
      order_items: {
        'for-delivery': [
          {
            imgIconPath: 'shop-order-progress/order-icons/order-taken.svg',
            alt: 'Order is taken',
            message: 'Bestellung aufgenommen',
            active_for: ['paid', 'taken', 'ontheway', 'ready', 'done'],
            broken_for: ['broken', 'unpaid'],
          },
          {
            imgIconPath: 'shop-order-progress/order-icons/order-chef-preparing-food.png',
            alt: 'The chef is preparing the food',
            message: 'Der koch kocht für dich',
            active_for: ['taken', 'ontheway', 'ready', 'done'],
            broken_for: ['denied'],
          },
          {
            imgIconPath: 'shop-order-progress/order-icons/order-delivery.png',
            alt: 'Delivery of the order',
            message: 'Bestellung auf dem Weg',
            active_for: ['ready', 'ontheway', 'done'],
            broken_for: ['something'],
          },
          {
            imgIconPath: 'shop-order-progress/order-icons/order-arrived.svg',
            alt: 'Order arrived',
            message: 'Bestellung angekommen!',
            active_for: ['done'],
            broken_for: ['denied'],
          },
        ],
        'pick-by-myself': [
          {
            imgIconPath: 'shop-order-progress/order-icons/order-taken.svg',
            alt: 'Order is taken',
            message: 'Bestellung aufgenommen',
            active_for: ['paid', 'taken', 'ontheway', 'ready', 'done'],
            broken_for: ['broken', 'unpaid'],
          },
          {
            imgIconPath: 'shop-order-progress/order-icons/order-chef-preparing-food.png',
            alt: 'The chef is preparing the food',
            message: 'Der koch kocht für dich',
            active_for: ['taken', 'ontheway', 'ready', 'done'],
            broken_for: ['denied'],
          },
          {
            imgIconPath: 'shop-order-progress/order-icons/order-pick-by-myself.png',
            alt: 'Delivery of the order',
            message: 'Bestellung ist bereit zu nehmen',
            active_for: ['ready', 'ontheway', 'done'],
            broken_for: ['something'],
          },
          {
            imgIconPath: 'shop-order-progress/order-icons/order-done.png',
            alt: 'Order arrived',
            message: 'Bestellung ist fertig',
            active_for: ['done'],
            broken_for: ['denied'],
          },
        ],
      },
    };
  },

  methods: {
    getImgUrl(pic) {
      const cdnUrl = process.env.MTK_CDN;
      return `${cdnUrl}/${pic}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_order-progress.scss';
</style>
